import { TextField } from '@mui/material';
import React from 'react';

import typography from '../../../theme/typography';

interface SelectOption {
  key: number | string;
  label: string;
  value: boolean | number | string;
}

interface SelectInputProps {
  dataTestId?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: Array<SelectOption>;
  value: boolean | number | string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  dataTestId,
  onChange,
  options,
  value,
}) => (
  <TextField
    SelectProps={{
      IconComponent: () => null, // Use an empty component to hide the arrow
      native: true,
      style: {
        marginTop: 0,
      },
    }}
    inputProps={{
      'data-testid': dataTestId,
      style: {
        paddingBottom: 0,
        paddingRight: '20px',
        paddingTop: 0,
        ...typography['text-xs-demi-bold'],
      },
    }}
    onChange={onChange}
    select
    value={value}
  >
    {options.map((option) => (
      <option key={option.key} value={option.value.toString()}>
        {option.label}
      </option>
    ))}
  </TextField>
);

export default SelectInput;
