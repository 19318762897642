import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider, DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Select, MenuItem, Typography, FormHelperText, OutlinedInput } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HiddenField, FieldsFor } from 'react-rails-form-helpers';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

// Empty component to remove icon from Select
const NoIconComponent = () => null;

const theme = createTheme({
  typography: {
    fontFamily: ['"Avenir Next"', 'Montserrat', 'sans-serif'].join(',')
  },
  components: {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '.MuiList-root': {
            display: 'flex',
            padding: '16px',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',  
            maxHeight: 'calc(100vh - 100px)', 
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            borderRight: '1px solid rgba(26, 25, 25, 0.10)'
          },
          '.MuiMenuItem-gutters': {
            color: '#1A1919',
            textAlign: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            width: '36px',
            height: '36px',
            fontWeight: 600,
            lineHeight: '143%',
            letterSpacing: '0.17px',
            borderRadius: '13px',
            '&.Mui-selected': {
              backgroundColor: '#eceef3',
              color: '#1C5BFE',
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#D4D6DA',
            },
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            color: '#1c5bfe'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root': {
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            color: '#666',
            height: '40px',
            padding: '10px 18px',
            alignItems: 'center',
            display: 'inline-flex',
            gap: '10px',
            fontSize: '14px',
            borderRadius: '8px',
            border: '1px solid var(--ButtonOutlines, #DCDEE2)',
            background: 'var(--background-paper-elevation-8, #FFF)',
            '&.MuiInputBase-formControl': {
              width: '150px',
              '.MuiOutlinedInput-input': {
                padding: '0',
                fontWeight: '600',
                border: 'none',
                color: '#666',
              }
            },
            '.MuiTypography-caption': {
              fontWeight: '600',
              fontSize: '14px',
            }
          },
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: '#666',
          fontFamily: "Avenir Next",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal' ,
          width: '100%',
          textOverflow: 'clip',
          padding: '0 0 0 0 !important', // :(
        }
      }
    }
  }
});

const parseTimeString = (timeString, creatorTimeZone) => {
  if (typeof timeString === 'string') {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return dayjs().tz(creatorTimeZone).hour(hours).minute(minutes).second(seconds);
  }
  return dayjs().tz(creatorTimeZone).hour(0).minute(0).second(0);
};

const TimePickerComponent = ({ value, onChange, minTime, maxTime, creatorTimeZone, isOpen, setIsOpen }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
    <DesktopTimePicker
      value={value}
      size='small'
      onChange={onChange}
      onAccept={onChange}
      notched={false}
      label={''}
      shrink={false}
      minutesStep={5}
      minTime={minTime}
      maxTime={maxTime}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      slotProps={{
        textField: {
          onClick: () => setIsOpen(true),
          size: 'small',
          InputProps: {
            endAdornment: <Typography variant="caption" sx={{ marginLeft: 0 }}>{dayjs().tz(creatorTimeZone).format('z')}</Typography>,
          },
        },
      }}
      slots={{
        openPickerButton: () => null
      }}
    />
  </Box>
);

TimePickerComponent.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  minTime: PropTypes.object,
  maxTime: PropTypes.object,
  creatorTimeZone: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

function AlertTimeframeSelector({ scheduledTimeStart, scheduledTimeEnd, scheduledDay, scheduledOccurrence, creatorTimeZone }) {
  const defaultStartTime = parseTimeString('00:00:00', creatorTimeZone);
  const defaultEndTime = parseTimeString('12:00:00', creatorTimeZone);
  const [startTime, setStartTime] = useState(parseTimeString(scheduledTimeStart ?? defaultStartTime, creatorTimeZone));
  const [endTime, setEndTime] = useState(parseTimeString(scheduledTimeEnd ?? defaultEndTime, creatorTimeZone));
  const [selectedOption, setSelectedOption] = useState(scheduledOccurrence);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(scheduledDay);
  const [isStartPickerOpen, setIsStartPickerOpen] = useState(false);
  const [isEndPickerOpen, setIsEndPickerOpen] = useState(false);

  const showPickers = selectedOption === 'between';

  useEffect(() => {
    const parsedStartTime = typeof scheduledTimeStart === 'string' ? parseTimeString(scheduledTimeStart, creatorTimeZone) : defaultStartTime;
    const parsedEndTime = typeof scheduledTimeEnd === 'string' ? parseTimeString(scheduledTimeEnd, creatorTimeZone) : defaultEndTime;

    setStartTime(parsedStartTime);
    setEndTime(parsedEndTime);
  }, [scheduledTimeStart, scheduledTimeEnd, creatorTimeZone]);

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };

  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  return (
    <ThemeProvider theme={theme}>
      <FieldsFor name="alert_rule">
        <HiddenField name="scheduled_time_start" value={startTime ? startTime.format('HH:mm:ss') : defaultStartTime} />
        <HiddenField name="scheduled_time_end" value={endTime ? endTime.format('HH:mm:ss') : defaultEndTime} />
        <HiddenField name="scheduled_day" value={selectedDropdownItem} />
        <HiddenField name="scheduled_occurrence" value={selectedOption} />
      </FieldsFor>
      <Box marginTop="10px">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" sx={{ color: '#4A4848', marginRight: '12px', fontWeight: 400, fontSize: '16px' }}>
              {I18n.t('alert_rules.form.scheduled.occurrence')}
            </Typography>
            <Select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              IconComponent={NoIconComponent}
              sx={{
                marginRight: '12px',
                fontWeight: '600',
                '& .MuiOutlinedInput-root': {
                  borderColor: '#DCDEE2',
                  borderRadius: '8px',
                  borderWidth: '1px',
                  width: '10px'
                },
                '& .MuiOutlinedInput-input': {
                  background: 'var(--background-paper-elevation-8, #FFF)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                }
              }}
            >
              <MenuItem value="anytime">{I18n.t('alert_rules.form.scheduled.occurrence_options.anytime')}</MenuItem>
              <MenuItem value="between">{I18n.t('alert_rules.form.scheduled.occurrence_options.between')}</MenuItem>
            </Select>
            {showPickers && (
              <>
                <TimePickerComponent
                  value={startTime}
                  onChange={handleStartTimeChange}
                  creatorTimeZone={creatorTimeZone}
                  isOpen={isStartPickerOpen}
                  setIsOpen={setIsStartPickerOpen}
                />
                <Typography variant="h6" sx={{ color: '#4A4848', marginRight: '12px', fontWeight: 400, fontSize: '16px' }}>
                  {I18n.t('general.and')}
                </Typography>
                <TimePickerComponent
                  value={endTime}
                  onChange={handleEndTimeChange}
                  creatorTimeZone={creatorTimeZone}
                  isOpen={isEndPickerOpen}
                  setIsOpen={setIsEndPickerOpen}
                />
              </>
            )}
            {showPickers && (
              <Select
                value={selectedDropdownItem}
                onChange={(e) => setSelectedDropdownItem(e.target.value)}
                IconComponent={NoIconComponent}
                autoWidth={true}
                sx={{
                  '& .MuiSelect-select': {
                    padding: '0'
                  }
                }}
                input={<OutlinedInput sx={{fontWeight: '600'}} />}
              >
                {Object.entries(I18n.t('alert_rules.form.scheduled.options', { returnObjects: true })).map(([key, value]) => (
                  <MenuItem key={key} value={key} >
                    {value}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
        </LocalizationProvider>
      </Box>
    </ThemeProvider>
  );
}

AlertTimeframeSelector.propTypes = {
  scheduledTimeStart: PropTypes.string,
  scheduledTimeEnd: PropTypes.string,
  scheduledDay: PropTypes.string.isRequired,
  scheduledOccurrence: PropTypes.string.isRequired,
  creatorTimeZone: PropTypes.string.isRequired
};

export default AlertTimeframeSelector;