import { Box, Button, CircularProgress, Link } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useLoginUserMutation } from '../../features/auth';
import { addNotificationMessage } from '../../features/notifications';
import { RoutesEnum } from '../../routesEnum';
import { ArrowLeftIcon } from '../icons/other';
import PasswordInput from './PasswordInput';
import { AppDispatch } from './store';
import { Form, Input } from './styles';

interface EmailPasswordFormProps {
  email: string;
  onBack: () => void;
}

interface SubmitButtonProps {
  isSubmitting: boolean;
  isValid: boolean;
  submitForm: () => void;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isSubmitting,
  isValid,
  submitForm,
}) => {
  const { t } = useTranslation(['login', 'common']);
  return (
    <Button
      color="primary"
      data-testid="sign-in-button"
      disabled={!isValid || isSubmitting}
      endIcon={isSubmitting && <CircularProgress size={20} />}
      onClick={submitForm}
      type="submit"
      variant="contained"
    >
      {isSubmitting ? t('common:loading') : t('login:signIn')}
    </Button>
  );
};

const EmailPasswordForm: React.FC<EmailPasswordFormProps> = ({
  email,
  onBack,
}) => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [loginUser, { isLoading: isSubmitting }] = useLoginUserMutation();

  const validationSchema = Yup.object({
    email: Yup.string().email(t('invalidEmail')).required(t('emailRequired')),
    password: Yup.string().required(t('passwordRequired')),
  });

  const { errors, getFieldProps, isValid, submitForm, touched } = useFormik({
    initialValues: {
      email,
      password: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const { redirectUrl } = await loginUser(values).unwrap();
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate(RoutesEnum.HOME);
        }
      } catch (error) {
        dispatch(
          addNotificationMessage({
            message: (error as { data: { error: string } }).data.error,
            severity: 'error',
          }),
        );
      }
      setSubmitting(false);
    },
    validationSchema,
  });

  return (
    <>
      <Form
        autoComplete="on"
        data-testid="password-form"
        onSubmit={(e) => e.preventDefault()}
      >
        <Input
          id="email"
          label={t('email')}
          {...getFieldProps('email')}
          autoComplete="username"
          autoFocus
          data-testid="password-email-input"
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          type="text"
          variant="standard"
        />
        <PasswordInput
          data-testid="password-input"
          {...getFieldProps('password')}
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
        />
        <SubmitButton
          isSubmitting={isSubmitting}
          isValid={isValid}
          submitForm={submitForm}
        />
      </Form>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          width: '100%',
        }}
      >
        <Link
          component="button"
          data-testid="back-button"
          onClick={onBack}
          style={{
            alignItems: 'center',
            display: 'flex',
            gap: '8px',
            justifyContent: 'center',
          }}
        >
          <ArrowLeftIcon style={{ height: '16px', width: '16px' }} />
          {t('back')}
        </Link>
        <Link
          data-testid="forgot-password-link"
          href={RoutesEnum.FORGOT_PASSWORD}
          onClick={() => navigate(RoutesEnum.FORGOT_PASSWORD)}
        >
          {t('forgotPassword')}
        </Link>
      </Box>
    </>
  );
};

export default EmailPasswordForm;
