import { AlertRule } from '@interfaces/alertRule';
import { SensorTypeSetting } from '@interfaces/sensorTypeSetting';
import { fireEvent, render, screen } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import { beforeAll, beforeEach, describe, expect, it } from 'vitest';

import alertRuleFactory from '../../../factories/alertRuleFactory';
import { setAlertRule } from '../../../features/alertRules';
import translations from '../../../translations';
import store from '../store';
import ThresholdSensorTypeSettingForm from './ThresholdSensorTypeSettingForm';

describe('ThresholdSensorTypeSettingForm', () => {
  let mockAlertRule: AlertRule;
  let mockSensorTypeSetting: SensorTypeSetting;

  beforeAll(() => {
    translations.init();
  });

  beforeEach(() => {
    // Create a mock sensor type setting for a threshold type
    mockAlertRule = alertRuleFactory.build();
    mockSensorTypeSetting = mockAlertRule.sensorTypeSettings[0];

    // Dispatch a mock alert rule to the store
    store.dispatch(setAlertRule(mockAlertRule));
  });

  const setup = (overrides = {}) => {
    const props = {
      setting: mockSensorTypeSetting,
      ...overrides,
    };

    render(
      <Provider store={store}>
        <ThresholdSensorTypeSettingForm {...props} />
      </Provider>,
    );

    return props;
  };

  it('renders the if condition text', () => {
    setup();
    expect(screen.getByText(/if/i)).toBeInTheDocument();
    expect(screen.getByText('Temperature')).toBeInTheDocument();
  });

  it('renders the SensorLimitField with correct values', () => {
    setup();
    const higherLimitInput = screen.getByTestId(
      `higher-limit-field-${mockSensorTypeSetting.typeCode}`,
    );
    const lowerLimitInput = screen.getByTestId(
      `lower-limit-field-${mockSensorTypeSetting.typeCode}`,
    );

    expect(higherLimitInput).toBeInTheDocument();
    expect(lowerLimitInput).toBeInTheDocument();
  });

  it('calls handleValueChange when updating higher limit', () => {
    setup();
    const higherLimitInput = screen.getByTestId(
      `higher-limit-field-${mockSensorTypeSetting.typeCode}`,
    );
    fireEvent.change(higherLimitInput, { target: { value: 150 } });

    // Ensure the store is updated correctly
    const updatedState = store.getState().alertRule.sensorTypeSettings[0];
    expect(updatedState.higherLimit).toBe('150');
  });

  it('calls handleValueChange when updating lower limit', () => {
    setup();
    const lowerLimitInput = screen.getByTestId(
      `lower-limit-field-${mockSensorTypeSetting.typeCode}`,
    );
    fireEvent.change(lowerLimitInput, { target: { value: -10 } });

    // Ensure the store is updated correctly
    const updatedState = store.getState().alertRule.sensorTypeSettings[0];
    expect(updatedState.lowerLimit).toBe('-10');
  });

  it('renders the delay input field when alertOnFirstOutOfRange is false', () => {
    setup();
    expect(screen.getByText(/for longer than/i)).toBeInTheDocument();
    expect(
      screen.getByTestId(`set-delay-field-${mockSensorTypeSetting.typeCode}`),
    ).toBeInTheDocument();
  });

  it('calls handleValueChange when changing the set delay', () => {
    setup();
    const delayInput = screen.getByTestId(
      `set-delay-field-${mockSensorTypeSetting.typeCode}`,
    );
    fireEvent.change(delayInput, { target: { value: 5 } });

    // Ensure the store is updated correctly
    const updatedState = store.getState().alertRule.sensorTypeSettings[0];
    expect(updatedState.setDelay).toBe(5);
  });

  it('does not render the set delay field if alertOnFirstOutOfRange is true', () => {
    // Dispatch a mock alert rule to the store
    store.dispatch(
      setAlertRule({
        ...mockAlertRule,
        alertOnFirstOutOfRange: true,
      }),
    );

    setup();
    expect(screen.queryByTestId('set-delay-field')).not.toBeInTheDocument();
    expect(screen.queryByText(/for longer than/i)).not.toBeInTheDocument();
  });

  it('renders the correct unit for the higher and lower limit fields', () => {
    setup();
    const unitFields = screen.getAllByText('ºC');

    unitFields.forEach((unitField) => {
      expect(unitField).toBeInTheDocument();
    });
  });

  it('displays "0" in the text field when the value is zero', () => {
    // Create a new object with the modified lowerLimit
    const modifiedSetting = {
      ...mockSensorTypeSetting,
      lowerLimit: 0,
    };

    setup({ setting: modifiedSetting });
    const lowerLimitInput = screen.getByTestId(
      `lower-limit-field-${modifiedSetting.typeCode}`,
    ) as HTMLInputElement;

    expect(lowerLimitInput).toBeInTheDocument();
    expect(lowerLimitInput.value).toBe('0');
  });

  it('allows inputting decimal values and updates the store correctly', () => {
    setup();
    const lowerLimitInput = screen.getByTestId(
      `lower-limit-field-${mockSensorTypeSetting.typeCode}`,
    );

    fireEvent.change(lowerLimitInput, { target: { value: 0.5 } });

    // Ensure the store is updated correctly
    const updatedState = store.getState().alertRule.sensorTypeSettings[0];
    expect(updatedState.lowerLimit).toBe('0.5');
  });
});
