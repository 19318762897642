import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import React from 'react';

import TagFilter from './TagFilter';

describe('TagFilter', () => {
  let onSpy = vi.fn();
  let offSpy = vi.fn();

  beforeEach(() => {
    // Mock jQuery
    global.$ = vi.fn(() => ({
      off: offSpy,
      on: onSpy,
    }));
  });

  it('renders', () => {
    const props = {
      selectedIdsProp: [],
      validCustomGroupsProp: [],
      validEquipmentCategoriesProp: [],
      validLocationsProp: [],
    };

    render(<TagFilter {...props} />);

    screen.getAllByLabelText('Equipment Categories');
    screen.getAllByLabelText('Locations');
    screen.getAllByLabelText('Custom Groups');
  });
});
