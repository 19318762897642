import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SensorTypeSetting } from '../../../interfaces';
import { ALERT_DELAY_RANGES } from '../constants';
import SelectInput from '../partials/SelectInput';
import { RootState } from '../store';
import DelayInputField from './DelayInputField';
import IfConditionText from './IfConditionText';
import useSensorTypeSetting from './useSensorTypeSetting';

interface BooleanSensorTypeSettingFormProps {
  setting: SensorTypeSetting;
}

const BooleanSensorTypeSettingForm: React.FC<
  BooleanSensorTypeSettingFormProps
> = ({ setting }) => {
  const { t } = useTranslation('alertRuleSensorTypes');

  const validBooleanValues = useSelector(
    (state: RootState) => state.alertRuleForm.validBooleanValues,
  );

  const { handleValueChange } = useSensorTypeSetting(setting);

  const handleMinutesChange = (minutes: number) => {
    const seconds = (setting.setDelay || 0) % 60;
    handleValueChange('setDelay', minutes * 60 + seconds);
  };

  const handleSecondsChange = (seconds: number) => {
    const minutes = Math.floor((setting.setDelay || 0) / 60);
    handleValueChange('setDelay', minutes * 60 + seconds);
  };

  const booleanOptions = validBooleanValues.map((option) => ({
    key: option.value.toString(),
    label: option.label,
    value: option.value,
  }));

  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item md={3} sm={6} xs={12}>
        <IfConditionText
          i18nKey={'messages.generic.if'}
          values={{ alert_type: setting.name }}
        />
      </Grid>
      <Grid
        alignItems="center"
        container
        gap={'5px'}
        item
        md={'auto'}
        sm={6}
        xs={12}
      >
        <Typography variant="text-xs">
          {t('messages.alertConditions.isIn')}
        </Typography>
        <SelectInput
          dataTestId={`boolean-value-field-${setting.typeCode}`}
          onChange={(e) =>
            handleValueChange('booleanValue', e.target.value === 'true')
          }
          options={booleanOptions}
          value={setting.booleanValue ?? false}
        />
        <Typography variant="text-xs-bold">{setting.unit}</Typography>
      </Grid>
      <Grid
        alignItems="center"
        container
        gap={'5px'}
        item
        md={'auto'}
        sm={6}
        xs={12}
      >
        {/* Handle delay in minutes */}
        <Typography variant="text-xs">
          {t('messages.alertConditions.forLongerThan')}
        </Typography>
        <DelayInputField
          dataTestId={`minutes-set-delay-field-${setting.typeCode}`}
          max={ALERT_DELAY_RANGES['dry_contact_closed'].minutes.max}
          min={ALERT_DELAY_RANGES['dry_contact_closed'].minutes.min}
          onChange={handleMinutesChange}
          value={Math.floor((setting.setDelay || 0) / 60)}
        />
        {/* Handle delay in seconds */}
        <DelayInputField
          dataTestId={`seconds-set-delay-field-${setting.typeCode}`}
          max={ALERT_DELAY_RANGES['dry_contact_closed'].seconds.max}
          min={
            setting.setDelay === 0
              ? 15
              : ALERT_DELAY_RANGES['dry_contact_closed'].seconds.min
          }
          onChange={handleSecondsChange}
          step={ALERT_DELAY_RANGES['dry_contact_closed'].seconds.step}
          unit={t('measurement.seconds')}
          value={(setting.setDelay || 0) % 60}
        />
      </Grid>
    </Grid>
  );
};

export default BooleanSensorTypeSettingForm;
