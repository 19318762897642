import { fireEvent, render, screen } from '@testing-library/react';
import React from 'react';
import { describe, expect, it, vi } from 'vitest';

import SensorLimitField from './SensorLimitField';

describe('SensorLimitField', () => {
  const setup = (overrides = {}) => {
    const props = {
      color: '#ff0000',
      label: 'Temperature Limit',
      onChange: vi.fn(),
      unit: '°C',
      value: 25,
      ...overrides,
    };

    render(<SensorLimitField {...props} />);
    return props;
  };

  it('renders the label correctly', () => {
    const { label } = setup();
    const labelElement = screen.getByText(label);
    expect(labelElement).toBeInTheDocument();
  });

  it('renders the TextField with the correct value', () => {
    const { value } = setup();
    const inputElement = screen.getByRole('spinbutton') as HTMLInputElement;
    expect(inputElement).toBeInTheDocument();
    expect(inputElement.value).toBe(String(value));
  });

  it('renders the unit correctly', () => {
    const { unit } = setup();
    const unitElement = screen.getByText(unit);
    expect(unitElement).toBeInTheDocument();
  });

  it('applies the correct styles to the input field', () => {
    const { color } = setup();
    const inputElement = screen.getByRole('spinbutton') as HTMLInputElement;
    expect(inputElement).toHaveStyle({
      color,
      padding: '0 0 0 20px',
    });
  });

  it('calls the onChange handler when the input value changes', () => {
    const onChangeMock = vi.fn();
    setup({ onChange: onChangeMock });
    const inputElement = screen.getByRole('spinbutton') as HTMLInputElement;

    fireEvent.change(inputElement, { target: { value: 30 } });

    expect(onChangeMock).toHaveBeenCalledTimes(1);
    expect(onChangeMock).toHaveBeenCalledWith('30');
  });
});
