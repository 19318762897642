import { faker } from '@faker-js/faker';
import { fireEvent, render, screen, waitFor } from '@testing-library/react';
import { HttpResponse, http } from 'msw';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { server } from '../../features/auth/mocks/server';
import { RoutesEnum } from '../../routesEnum';
import translations from '../../translations';
import LoginPage from './LoginPage';
import store from './store';

const renderWithProviders = (ui: React.ReactElement) => {
  return render(
    <Provider store={store}>
      <BrowserRouter>{ui}</BrowserRouter>
    </Provider>,
  );
};

describe('LoginPage', () => {
  beforeAll(() => {
    translations.init();
    server.listen();
  });
  afterEach(() => server.resetHandlers());
  afterAll(() => server.close());

  test('renders login page', () => {
    renderWithProviders(<LoginPage />);
    expect(screen.getByTestId('login-page')).toBeInTheDocument();
    expect(screen.getByTestId('logo')).toBeInTheDocument();
    expect(screen.getByTestId('sign-in-title')).toBeInTheDocument();
  });

  test('handles email form submission and displays password form', async () => {
    renderWithProviders(<LoginPage />);
    const email = faker.internet.email();

    fireEvent.change(screen.getByLabelText(/email/i), {
      target: { value: email },
    });

    fireEvent.click(screen.getByTestId('next-button'));

    await waitFor(() => {
      expect(screen.getByTestId('password-form')).toBeInTheDocument();
    });
  });

  test('handles SSO path redirect', async () => {
    renderWithProviders(<LoginPage />);
    const ssoEmail = 'sso@example.com';

    fireEvent.change(screen.getByLabelText(/email/i), {
      target: { value: ssoEmail },
    });
    fireEvent.click(screen.getByTestId('next-button'));

    await waitFor(() => {
      // Assert that the page navigates to the SSO path
      expect(window.location.pathname).toBe('/sso/path/example');
    });
  });

  test('handles password form submission and navigates on success', async () => {
    renderWithProviders(<LoginPage />);
    const email = faker.internet.email();

    // Simulate the email form submission
    fireEvent.change(screen.getByLabelText(/email/i), {
      target: { value: email },
    });
    fireEvent.click(screen.getByTestId('next-button'));

    await waitFor(() => {
      expect(screen.getByTestId('password-form')).toBeInTheDocument();
    });

    // Simulate the password form submission
    const password = faker.internet.password();
    fireEvent.change(screen.getByLabelText('Password'), {
      target: { value: password },
    });
    fireEvent.click(screen.getByTestId('sign-in-button'));

    await waitFor(() => {
      // Assert that the page navigates to the home route
      expect(window.location.pathname).toBe(RoutesEnum.HOME);
    });
  });

  test('displays error message on failed sign in', async () => {
    renderWithProviders(<LoginPage />);
    const email = faker.internet.email();

    // Modify the server response to simulate a failed sign-in
    server.use(
      http.post('/users/sign_in', async () => {
        return new HttpResponse(
          JSON.stringify({
            error: 'Invalid credentials',
          }),
          { status: 401 },
        );
      }),
    );

    // Simulate the email form submission
    fireEvent.change(screen.getByLabelText(/email/i), {
      target: { value: email },
    });
    fireEvent.click(screen.getByTestId('next-button'));

    await waitFor(() => {
      expect(screen.getByTestId('password-form')).toBeInTheDocument();
    });

    // Simulate the password form submission
    fireEvent.change(screen.getByLabelText('Password'), {
      target: { value: 'wrongpassword' },
    });
    fireEvent.click(screen.getByTestId('sign-in-button'));

    await waitFor(() => {
      // Assert that the error message is displayed
      expect(screen.getByText(/invalid credentials/i)).toBeInTheDocument();
    });
  });

  test('navigates to home if redirectUrl is not provided', async () => {
    renderWithProviders(<LoginPage />);
    const email = faker.internet.email();

    // Simulate the email form submission
    fireEvent.change(screen.getByLabelText(/email/i), {
      target: { value: email },
    });
    fireEvent.click(screen.getByTestId('next-button'));

    await waitFor(() => {
      expect(screen.getByTestId('password-form')).toBeInTheDocument();
    });

    // Simulate the password form submission without redirectUrl
    const password = faker.internet.password();
    server.use(
      http.post('/users/sign_in', async () => {
        return new HttpResponse(
          JSON.stringify({
            email,
            id: 1,
          }),
          { headers: { 'Content-Type': 'application/json' } },
        );
      }),
    );

    fireEvent.change(screen.getByLabelText('Password'), {
      target: { value: password },
    });
    fireEvent.click(screen.getByTestId('sign-in-button'));

    await waitFor(() => {
      // Assert that the page navigates to the home route
      expect(window.location.pathname).toBe(RoutesEnum.HOME);
    });
  });

  test('handles back button click to return to email form', async () => {
    renderWithProviders(<LoginPage />);
    const email = faker.internet.email();

    // Simulate the email form submission
    fireEvent.change(screen.getByLabelText(/email/i), {
      target: { value: email },
    });
    fireEvent.click(screen.getByTestId('next-button'));

    await waitFor(() => {
      expect(screen.getByTestId('password-form')).toBeInTheDocument();
    });

    // Simulate the back button click
    fireEvent.click(screen.getByTestId('back-button'));

    await waitFor(() => {
      // Assert that the email form is displayed again
      expect(screen.getByTestId('email-form')).toBeInTheDocument();
    });
  });
});
