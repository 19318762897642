import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../components/navigation/store';

// Define a type for the slice state
interface NavigationBarState {
  collapsed: boolean;
}

// Define the initial state using that type
const initialState: NavigationBarState = {
  collapsed: Cookies.get(CONSTANTS.collapsedDrawerCookieId) === 'true',
};

export const navigationBarSlice = createSlice({
  initialState,
  name: 'navigationBar',
  reducers: {
    setCollapsed: (state) => {
      state.collapsed = !state.collapsed;

      if (state.collapsed) {
        window.dispatchEvent(new CustomEvent('navigatior-bar:open'));
      } else {
        window.dispatchEvent(new CustomEvent('navigatior-bar:close'));
      }
    },
  },
});

export const { setCollapsed } = navigationBarSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCollapsed = (state: RootState) =>
  state.navigationBar.collapsed;

export default navigationBarSlice.reducer;
