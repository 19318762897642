import { faker } from '@faker-js/faker';
import { cleanup, render, screen } from '@testing-library/react';
import * as React from 'react';

import MFEGuestIframe from './index';
import { DashboardWindow } from './types';
import utils from './utils';

function TestComponent() {
  return (
    <MFEGuestIframe
      appName={faker.word.noun()}
      appPath="/app"
      appURL={faker.internet.url()}
      currentCustomer={{
        name: faker.company.name(),
        uuid: faker.string.uuid(),
      }}
      currentCustomerGroup={{
        customer_id: faker.number.int({ min: 1 }),
        name: faker.word.noun(),
        slug: faker.word.noun(),
        uuid: faker.string.uuid(),
      }}
      environmentVariables={{
        hello: faker.word.noun(),
      }}
    />
  );
}

describe('mfe guest iframe', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the iframe', () => {
    const spy = vi.fn();
    (window as DashboardWindow).groupSwitcher = {
      setDropdownBackdrop: spy,
    };
    vi.spyOn(utils.webAppMessaging.adapter, 'loadGuestApp').mockResolvedValue(
      {} as never,
    );

    render(<TestComponent />);

    const iframe = screen.getAllByTestId('guest-app-iframe');
    expect(iframe[0]).toBeDefined();

    expect(spy).toHaveBeenCalledTimes(1);
  });
});
