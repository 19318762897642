import React from 'react';
import PropTypes from 'prop-types';
import { FieldsFor, HiddenField } from 'react-rails-form-helpers';
import ThresholdIcon from '../icons/alert_types/ThresholdIcon';
import ConnectionIcon from '../icons/alert_types/ConnectionIcon';
import PowerIcon from '../icons/alert_types/PowerIcon';
import ErrorIcon from '../icons/alert_types/ErrorIcon';
import RadioGroupOption from './partials/RadioGroupOption';
import {
  ALERT_TYPE_THRESHOLD,
  ALERT_TYPE_CONNECTION,
  ALERT_TYPE_POWER,
  ALERT_TYPE_ERROR,
} from './constants';

const getAlertTypeIcon = (alertType) => {
  switch (alertType) {
    case ALERT_TYPE_THRESHOLD:
      return <ThresholdIcon />;
    case ALERT_TYPE_CONNECTION:
      return <ConnectionIcon />;
    case ALERT_TYPE_POWER:
      return <PowerIcon />;
    case ALERT_TYPE_ERROR:
      return <ErrorIcon />;
  }
};

const AlertTypeSelection = ({
  alertTypeOptions,
  selectedAlertType,
  setAlertType,
}) => (
  <div className={'card-wrapper radio_buttons'}>
    <label className="required">
      <abbr title="required">*</abbr>{' '}
      {I18n.t('simple_form.labels.alert_rule.alert_type')}
    </label>
    <FieldsFor name="alert_rule">
      <HiddenField name={'alert_type'} value={selectedAlertType ?? ''} />
    </FieldsFor>
    {alertTypeOptions.map((alertTypeOptionAlertType, index) => (
      <RadioGroupOption
        key={index}
        checked={selectedAlertType === alertTypeOptionAlertType}
        onClick={() => {
          setAlertType(alertTypeOptionAlertType);
        }}
        additionalClassNames={['js-input-alert-type']}
      >
        {getAlertTypeIcon(alertTypeOptionAlertType)}
        <span>
          {I18n.t(`alert_rules.form.alert_types.${alertTypeOptionAlertType}`)}
        </span>
      </RadioGroupOption>
    ))}
    <span className="hint">
      {I18n.t('simple_form.hints.alert_rule.alert_type')}
    </span>
  </div>
);

AlertTypeSelection.propTypes = {
  alertTypeOptions: PropTypes.array.isRequired,
};

export default AlertTypeSelection;
