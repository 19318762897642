import { SensorTypeSetting } from '@interfaces/sensorTypeSetting';
import { fireEvent, render, screen } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import { beforeAll, beforeEach, describe, expect, it } from 'vitest';

import alertRuleFactory from '../../../factories/alertRuleFactory';
import { setAlertRule } from '../../../features/alertRules';
import translations from '../../../translations';
import store from '../store';
import BatterySensorTypeSettingForm from './BatterySensorTypeSettingForm';

describe('BatterySensorTypeSettingForm', () => {
  let mockSensorTypeSetting: SensorTypeSetting;

  beforeAll(() => {
    translations.init();
  });

  beforeEach(() => {
    const mockAlertRule = alertRuleFactory.batteryAlert().build();
    mockSensorTypeSetting = mockAlertRule.sensorTypeSettings[0];

    store.dispatch(setAlertRule(mockAlertRule));
  });

  const setup = (overrides = {}) => {
    const props = {
      setting: mockSensorTypeSetting,
      ...overrides,
    };

    render(
      <Provider store={store}>
        <BatterySensorTypeSettingForm {...props} />
      </Provider>,
    );

    return props;
  };

  it('renders the if condition text', () => {
    setup();
    expect(screen.getByText(/if/i)).toBeInTheDocument();
    expect(screen.getByText('Battery')).toBeInTheDocument();
  });

  it('renders the SelectInput with the correct options', () => {
    setup();
    const selectInput = screen.getByTestId(
      `repeat-interval-field-${mockSensorTypeSetting.typeCode}`,
    );
    expect(selectInput).toBeInTheDocument();

    // Check if all options are rendered
    expect(screen.getByText('1 day')).toBeInTheDocument();
    for (let i = 2; i <= 15; i++) {
      expect(screen.getByText(`${i} days`)).toBeInTheDocument();
    }
  });

  it('calls handleValueChange when selecting a new option', () => {
    setup();
    const selectInput = screen.getByTestId(
      `repeat-interval-field-${mockSensorTypeSetting.typeCode}`,
    );
    fireEvent.change(selectInput, { target: { value: '7' } });

    // Ensure the store is updated correctly
    const updatedState = store.getState().alertRule.sensorTypeSettings[0];
    expect(updatedState.repeatInterval).toBe(7);
  });

  it('renders the replacement warning when machineTypes include element_t', () => {
    setup();
    expect(screen.getByText(/AAA Lithium/i)).toBeInTheDocument();
  });

  it('does not render the replacement warning when machineTypes do not include element_t', () => {
    const customAlertRule = alertRuleFactory.batteryAlert().build({
      machineTypes: ['element_a'],
    });
    store.dispatch(setAlertRule(customAlertRule));
    setup();

    expect(screen.queryByText(/AAA Lithium/i)).not.toBeInTheDocument();
  });
});
