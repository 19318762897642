import { GuestToHostNavigateInternal } from '@elemental/iframe-bridge';
import { faker } from '@faker-js/faker';
import { waitFor } from '@testing-library/dom';

import { handleInternalPageOpen } from './iframe-bridge';

describe('iframe-bridge integration', () => {
  describe('internal page open incoming message', () => {
    test('machineDetails - replacing the current page', () => {
      const payload: GuestToHostNavigateInternal = {
        cmd: 'window.internalPageOpen',
        props: {
          pageName: 'machineDetails',
          pageProps: {
            machineUuid: faker.string.uuid(),
          },
          replaceActivePage: true,
        },
      };

      handleInternalPageOpen(payload);
      waitFor(() =>
        expect(window.location.pathname).toStrictEqual(
          `/managed_machines/${payload.props.pageProps.machineUuid}`,
        ),
      );
    });

    test('machineDetails - opening a new window', () => {
      vi.spyOn(window, 'open');
      const payload: GuestToHostNavigateInternal = {
        cmd: 'window.internalPageOpen',
        props: {
          options: {
            features: 'width:500; height:500;',
            target: '_blank',
          },
          pageName: 'machineDetails',
          pageProps: {
            machineUuid: faker.string.uuid(),
          },
          replaceActivePage: false,
        },
      };

      handleInternalPageOpen(payload);

      expect(window.open).toHaveBeenCalledWith(
        `/managed_machines/${payload.props.pageProps.machineUuid}`,
        payload.props.options?.target,
        payload.props.options?.features,
      );
    });

    test('unkown page', () => {
      vi.spyOn(window, 'open');
      const payload: GuestToHostNavigateInternal = {
        cmd: 'window.internalPageOpen',
        props: {
          options: {
            features: 'width:500; height:500;',
            target: '_blank',
          },
          pageName: faker.word.noun(),
          pageProps: {
            machineUuid: faker.string.uuid(),
          },

          replaceActivePage: false,
        },
      };

      handleInternalPageOpen(payload);

      expect(window.open).toHaveBeenCalledTimes(0);
    });
  });
});
