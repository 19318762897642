import { SensorTypeSetting } from '@interfaces/sensorTypeSetting';
import { fireEvent, render, screen } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import { beforeAll, beforeEach, describe, expect, it } from 'vitest';

import alertRuleFactory from '../../../factories/alertRuleFactory';
import {
  setAlertRule,
  setValidBooleanValues,
} from '../../../features/alertRules';
import translations from '../../../translations';
import store from '../store';
import BooleanSensorTypeSettingForm from './BooleanSensorTypeSettingForm';

describe('BooleanSensorTypeSettingForm', () => {
  let mockSensorTypeSetting: SensorTypeSetting;

  beforeAll(() => {
    translations.init();
  });

  beforeEach(() => {
    // Mock the valid boolean values for the form
    store.dispatch(setValidBooleanValues([{ label: 'Alarm', value: false }]));

    const mockAlertRule = alertRuleFactory.booleanAlert().build();
    mockSensorTypeSetting = mockAlertRule.sensorTypeSettings[0];

    // Dispatch a mock alert rule to the store
    store.dispatch(setAlertRule(mockAlertRule));
  });

  const setup = (overrides = {}) => {
    const props = {
      setting: mockSensorTypeSetting,
      ...overrides,
    };

    render(
      <Provider store={store}>
        <BooleanSensorTypeSettingForm {...props} />
      </Provider>,
    );

    return props;
  };

  it('renders the if condition text', () => {
    setup();
    expect(screen.getByText(/if/i)).toBeInTheDocument();
    expect(screen.getByText(mockSensorTypeSetting.name)).toBeInTheDocument();
  });

  it('renders the SelectInput with the correct options', () => {
    setup();
    const selectInput = screen.getByTestId(
      `boolean-value-field-${mockSensorTypeSetting.typeCode}`,
    );
    expect(selectInput).toBeInTheDocument();

    expect(screen.getByText('Alarm')).toBeInTheDocument();
  });

  it('calls handleValueChange when selecting a boolean option', () => {
    setup();
    const selectInput = screen.getByTestId(
      `boolean-value-field-${mockSensorTypeSetting.typeCode}`,
    );
    fireEvent.change(selectInput, { target: { value: false } });

    // Ensure the store is updated correctly
    const updatedState = store.getState().alertRule.sensorTypeSettings[0];
    expect(updatedState.booleanValue).toBe(false);
  });

  it('calls handleValueChange when adjusting delay in minutes', () => {
    setup();
    const { setDelay } = mockSensorTypeSetting;
    const seconds = (setDelay ?? 0) % 60;

    const delayInputMinutes = screen.getByTestId(
      `minutes-set-delay-field-${mockSensorTypeSetting.typeCode}`,
    );
    fireEvent.change(delayInputMinutes, { target: { value: 5 } });

    // Ensure the store is updated correctly
    const updatedState = store.getState().alertRule.sensorTypeSettings[0];
    expect(updatedState.setDelay).toBe(300 + seconds); // 5 minutes
  });

  it('calls handleValueChange when adjusting delay in seconds', () => {
    setup();
    const { setDelay } = mockSensorTypeSetting;
    const minutes = Math.floor((setDelay ?? 0) / 60);

    const delayInputSeconds = screen.getByTestId(
      `seconds-set-delay-field-${mockSensorTypeSetting.typeCode}`,
    );
    fireEvent.change(delayInputSeconds, { target: { value: 30 } });

    // Ensure the store is updated correctly
    const updatedState = store.getState().alertRule.sensorTypeSettings[0];
    expect(updatedState.setDelay).toBe(minutes * 60 + 30);
  });

  it('renders the correct unit for the delay input fields', () => {
    setup();
    expect(screen.getAllByText(/minutes/i)).toBeTruthy();
    expect(screen.getAllByText(/seconds/i)).toBeTruthy();
  });
});
