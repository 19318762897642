import { fireEvent, render, screen } from '@testing-library/react';
import { describe, expect, it, vi } from 'vitest'; // Updated to use Vitest
import React from 'react';

import DelayInputField from './DelayInputField';

describe('DelayInputField', () => {
  const setup = (overrides = {}) => {
    const props = {
      max: 100,
      min: 0,
      onChange: vi.fn(),
      unit: 'minutes',
      value: 10,
      ...overrides,
    };

    render(<DelayInputField {...props} />);
    return props;
  };

  it('renders the TextField with the correct value', () => {
    const { value } = setup();
    const input = screen.getByRole('spinbutton') as HTMLInputElement;

    expect(input).toBeInTheDocument();
    expect(input.value).toBe(String(value));
  });

  it('renders the Typography with the correct unit', () => {
    const { unit } = setup();
    const typography = screen.getByText(unit);

    expect(typography).toBeInTheDocument();
  });

  it('calls the onChange handler when the input value changes', () => {
    const onChangeMock = vi.fn();
    setup({ onChange: onChangeMock });
    const input = screen.getByRole('spinbutton') as HTMLInputElement;

    fireEvent.change(input, { target: { value: 20 } });

    expect(onChangeMock).toHaveBeenCalledTimes(1);
    expect(onChangeMock).toHaveBeenCalledWith(20);
  });

  it('enforces the min and max constraints on the input', () => {
    const { max, min } = setup();
    const input = screen.getByRole('spinbutton') as HTMLInputElement;

    expect(input.max).toBe(String(max));
    expect(input.min).toBe(String(min));
  });

  it('renders the custom unit correctly when provided', () => {
    const unit = 'seconds';
    setup({ unit });
    const unitElement = screen.getByText(unit);
    expect(unitElement).toBeInTheDocument();
  });
});
