import {
  Autocomplete,
  Chip,
  CssBaseline,
  Grid,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import emLegacyTheme from '../../theme/emLegacyTheme';
import translations from '../../translations';
import updateHiddenField from '../../utils/updateHiddenField';
import { CloseIcon } from '../icons/notificationMessages';

translations.init();

type TagListItem = [string, number];
type TagListType = Array<TagListItem>;

interface TagFilterContainerProps {
  selectedIdsProp: Array<number>;
  validCustomGroupsProp: TagListType;
  validEquipmentCategoriesProp: TagListType;
  validLocationsProp: TagListType;
}

const labelStyle = {
  color: '#848484',
  fontSize: '13px',
  fontWeight: 700,
  padding: '3px',
  textTransform: 'uppercase'
};

const TagFilterContainer: FC<TagFilterContainerProps> = ({
  selectedIdsProp,
  validCustomGroupsProp,
  validEquipmentCategoriesProp,
  validLocationsProp,
}) => {
  const { t } = useTranslation('alerts');

  const [validEquipmentCategories, setValidEquipmentCategories] =
    useState<TagListType>(validEquipmentCategoriesProp);
  const [validLocations, setValidLocations] =
    useState<TagListType>(validLocationsProp);
  const [validCustomGroups, setValidCustomGroups] = useState<TagListType>(
    validCustomGroupsProp,
  );
  const [selectedIds, setSelectedIds] =
    useState<Array<number>>(selectedIdsProp);

  useEffect(() => {
    const handleAlertRuleFiltersLoaded = (
      _event: Event,
      validEquipmentCategories: TagListType,
      validLocations: TagListType,
      validCustomGroups: TagListType,
      selectedIds: Array<number>,
    ) => {
      setValidEquipmentCategories(validEquipmentCategories);
      setValidLocations(validLocations);
      setValidCustomGroups(validCustomGroups);
      setSelectedIds(selectedIds);
    };

    $(document).on('alertRuleFiltersLoaded', handleAlertRuleFiltersLoaded);

    // Cleanup the event listener on component unmount
    return () => {
      $(document).off('alertRuleFiltersLoaded', handleAlertRuleFiltersLoaded);
    };
  }, []);

  return (
    <ThemeProvider theme={emLegacyTheme}>
      <CssBaseline />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TagFilter
            label={t('filters.equipmentCategories.label')}
            placeholder={t('filters.equipmentCategories.placeholder')}
            selectedIds={selectedIds}
            tagList={validEquipmentCategories}
            tagType={'equipment-categories'}
          />
        </Grid>
        <Grid item xs={4}>
          <TagFilter
            label={t('filters.locations.label')}
            placeholder={t('filters.locations.placeholder')}
            selectedIds={selectedIds}
            tagList={validLocations}
            tagType={'locations'}
          />
        </Grid>
        <Grid item xs={4}>
          <TagFilter
            label={t('filters.customGroups.label')}
            placeholder={t('filters.customGroups.placeholder')}
            selectedIds={selectedIds}
            tagList={validCustomGroups}
            tagType={'custom-groups'}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

interface TagFilterProps {
  label: string;
  placeholder: string;
  selectedIds: Array<number>;
  tagList: TagListType;
  tagType: string;
}

const TagFilter: FC<TagFilterProps> = ({
  label,
  placeholder,
  selectedIds,
  tagList,
  tagType,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<TagListType>(
    tagList.filter((tag) => selectedIds?.includes(tag[1])),
  );

  useEffect(() => {
    updateHiddenField(
      `js-react-filters-${tagType}`,
      tagList.filter((tag) => selectedIds?.includes(tag[1])),
    );
    setSelectedOptions(() =>
      tagList.filter((tag) => selectedIds?.includes(tag[1])),
    );
  }, [selectedIds]);

  const handleChange = (event: SyntheticEvent, newValue: TagListType) => {
    if (event.type !== 'blur') {
      setSelectedOptions(newValue);
      updateHiddenField(`js-react-filters-${tagType}`, newValue);
      $(document).trigger('filters-changed');
    }
  };

  const id = `id-${tagType}`;

  // We only want the placeholder text to show if there are no options selected.
  // It *seems* like Autocomplete should be handling this already, but it wasn't, at least
  // with multiple enabled.
  const placeholderFunc = (): string | undefined => {
    if (selectedOptions.length > 0) {
      return undefined;
    }
    return placeholder;
  };

  const isOptionEqualToValue = (option: TagListItem, value: TagListItem) =>
    option[1] === value[1];

  return (
    <>
      <label htmlFor={id} style={{ marginBottom: 0, paddingBottom: 0 }}>
        <Typography sx={labelStyle} variant="text-xs-bold-caps">
          {label}
        </Typography>
      </label>
      <Autocomplete
        autoComplete={true}
        autoHighlight={true}
        autoSelect={true}
        getOptionLabel={(option) => option[0]}
        isOptionEqualToValue={isOptionEqualToValue}
        multiple
        onChange={handleChange}
        options={tagList}
        popupIcon={false}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            inputProps={{
              ...params.inputProps,
              id: id,
            }}
            multiline={true}
            placeholder={placeholderFunc()}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                color="primary"
                deleteIcon={
                  <CloseIcon
                    fontSize="large"
                    sx={{ '& path': { color: '#fff', strokeWidth: 3 } }}
                  />
                }
                key={key}
                label={option[0]}
                size="small"
                {...tagProps}
              />
            );
          })
        }
        size="small"
        value={selectedOptions}
      />
    </>
  );
};

export default TagFilterContainer;
