import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SensorTypeSetting } from '../../../interfaces';
import SelectInput from '../partials/SelectInput';
import { RootState } from '../store';
import IfConditionText from './IfConditionText';
import useSensorTypeSetting from './useSensorTypeSetting';

interface BatterySensorTypeSettingFormProps {
  setting: SensorTypeSetting;
}

const BatterySensorTypeSettingForm: React.FC<
  BatterySensorTypeSettingFormProps
> = ({ setting }) => {
  const { t } = useTranslation('alertRuleSensorTypes');

  const machineTypes = useSelector(
    (state: RootState) => state.alertRule.machineTypes,
  );
  const showBatteryMessage = machineTypes?.includes('element_t');

  const { handleValueChange } = useSensorTypeSetting(setting);

  const options = [...Array(15).keys()].map((i) => ({
    key: i,
    label: t('general.dayWithCount', { count: i + 1 }),
    value: i + 1,
  }));

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid
        alignItems="center"
        container
        direction="row"
        item
        spacing={2}
        xs={12}
      >
        <Grid item>
          <IfConditionText i18nKey="messages.battery.ifLevelBelow" />
        </Grid>
        <Grid item>
          <SelectInput
            dataTestId={`repeat-interval-field-${setting.typeCode}`}
            onChange={(e) =>
              handleValueChange('repeatInterval', parseInt(e.target.value, 10))
            }
            options={options}
            value={setting.repeatInterval || ''}
          />
        </Grid>
        <Grid item>
          <Typography variant="text-xs">
            {t('messages.battery.untilBatteriesReplace')}
          </Typography>
        </Grid>
      </Grid>
      {showBatteryMessage && (
        <Grid item xs={12}>
          <Typography color="error" variant="text-s">
            <Trans
              i18nKey="messages.battery.replacementWarning"
              ns="alertRuleSensorTypes"
            />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default BatterySensorTypeSettingForm;
