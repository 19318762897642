import { faker } from '@faker-js/faker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { fireEvent, render, screen, waitFor } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import { describe, expect, test, vi } from 'vitest';

import { RoutesEnum } from '../../routesEnum';
import translations from '../../translations';
import EmailPasswordForm from './EmailPasswordForm';
import store from './store';

const theme = createTheme();

const mockNavigate = vi.fn();

vi.mock('react-router-dom', async () => {
  const actual = await vi.importActual('react-router-dom');
  return {
    ...actual,
    useNavigate: () => mockNavigate,
  };
});

const renderWithProviders = (ui: React.ReactElement) => {
  return render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MemoryRouter>{ui}</MemoryRouter>
      </ThemeProvider>
    </Provider>,
  );
};

describe('EmailPasswordForm', () => {
  const mockOnBack = vi.fn();

  beforeAll(() => {
    translations.init();
  });

  test('renders email and password inputs', () => {
    renderWithProviders(
      <EmailPasswordForm email={faker.internet.email()} onBack={mockOnBack} />,
    );

    expect(screen.getByLabelText(/email/i)).toBeInTheDocument();
    expect(screen.getByLabelText('Password')).toBeInTheDocument();
    expect(screen.getByTestId('sign-in-button')).toBeInTheDocument();
  });

  test('handles form submission with valid inputs', async () => {
    const email = faker.internet.email();
    const password = faker.internet.password();

    renderWithProviders(
      <EmailPasswordForm email={email} onBack={mockOnBack} />,
    );

    fireEvent.change(screen.getByLabelText('Password'), {
      target: { value: password },
    });

    fireEvent.click(screen.getByTestId('sign-in-button'));

    await waitFor(() => {
      // Assert that the page navigates to the home route
      expect(window.location.pathname).toBe(RoutesEnum.HOME);
    });
  });

  test('displays validation errors for empty fields', async () => {
    renderWithProviders(
      <EmailPasswordForm email={faker.internet.email()} onBack={mockOnBack} />,
    );

    fireEvent.change(screen.getByLabelText(/email/i), {
      target: { value: 'invalid-email' },
    });
    fireEvent.blur(screen.getByLabelText(/email/i));

    fireEvent.change(screen.getByLabelText('Password'), {
      target: { value: '' },
    });
    fireEvent.blur(screen.getByLabelText('Password'));

    await waitFor(() => {
      expect(screen.getByText(/invalid email address/i)).toBeInTheDocument();
      expect(screen.getByText(/password is required/i)).toBeInTheDocument();
    });
  });

  test('navigates back when back button is clicked', () => {
    renderWithProviders(
      <EmailPasswordForm email={faker.internet.email()} onBack={mockOnBack} />,
    );

    fireEvent.click(screen.getByTestId('back-button'));

    expect(mockOnBack).toHaveBeenCalled();
  });

  test('navigates to forgot password page when link is clicked', async () => {
    renderWithProviders(
      <EmailPasswordForm email={faker.internet.email()} onBack={mockOnBack} />,
    );

    fireEvent.click(screen.getByTestId('forgot-password-link'));

    await waitFor(() => {
      expect(mockNavigate).toHaveBeenCalledWith(RoutesEnum.FORGOT_PASSWORD);
    });
  });
});
